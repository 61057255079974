<template>
  <div>
    <van-nav-bar
        title="信用贷"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div>
      <div class="name">问题一：</div>
      <div class="timu">
        您当前月收入为？
      </div>
      <van-radio-group v-model="shouru">
        <van-radio name="1" class="xuanxiang">A.3000元以下</van-radio>
        <van-radio name="2" class="xuanxiang">B.3000-5000元</van-radio>
        <van-radio name="3" class="xuanxiang">C.5000-8000元</van-radio>
        <van-radio name="4" class="xuanxiang">D.8000-1万元</van-radio>
        <van-radio name="5" class="xuanxiang">E.1万以上</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题二：</div>
      <div class="timu">
        您的工作单位？
      </div>
      <van-radio-group v-model="gongzuodanwei">
        <van-radio name="1" class="xuanxiang">A.机关事业单位，大型国企的工作人员，比如公务员、警察，事业单位的电视、报社等传媒单位工作人员以及市属医院的医生、护士等。</van-radio>
        <van-radio name="2" class="xuanxiang">B.世界500强的外企高管、高级白领或者优质机构的找工作人员，如银行，电力机构、电信单位、等收入较高的高管和工作人员。</van-radio>
        <van-radio name="3" class="xuanxiang">C.具有高级职称，高级技能的技术管理人员，比如注册会计师、执业律师，税务师、审计师、注册房地产评估师、注册资产评估师等高级专业人员。</van-radio>
        <van-radio name="4" class="xuanxiang">D.普通企业员工、个体工商户、自由职业者等。</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题三：</div>
      <div class="timu">
        您有无社保？
      </div>
      <van-radio-group v-model="shebao">
        <van-radio name="1" class="xuanxiang">A.有</van-radio>
        <van-radio name="2" class="xuanxiang">B.无</van-radio>
      </van-radio-group>
    </div>

    <van-divider />

    <div>
      <div class="name">问题四：</div>
      <div class="timu">
        您有无逾期历史？
      </div>
      <van-radio-group v-model="yuqi">
        <van-radio name="1" class="xuanxiang">A.有</van-radio>
        <van-radio name="2" class="xuanxiang">B.无</van-radio>
      </van-radio-group>
    </div>

    <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
      <van-button type="primary" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>

import {RadioGroup, Radio, NavBar, Divider, Button, Dialog} from 'vant';
export default {
  name: "xinyongdai",

  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [NavBar.name]: NavBar,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },

  data() {
    return {
      shouru: '',
      gongzuodanwei: '',
      shebao: '',
      yuqi: ''

    }
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    submit() {
      if (this.shouru === null || this.shouru === '') {
        Dialog.alert({
          message: '请选择您当前的月收入！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.gongzuodanwei === null || this.gongzuodanwei === '') {
        Dialog.alert({
          message: '请选择您的工作单位！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.shebao === null || this.shebao === '') {
        Dialog.alert({
          message: '请确认您是否有社保！',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.yuqi === null || this.yuqi === '') {
        Dialog.alert({
          message: '请确认您是否有逾期记录！',
        }).then(() => {
          // on close
        });
        return;
      }

      if (this.gongzuodanwei === '1' || this.gongzuodanwei === '2' || this.gongzuodanwei === '3') {
        window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?id=12997888"
      } else {
        window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?id=12997897"
      }

    }
  }

}
</script>

<style scoped>
.name {
  font-weight: bold;
  font-size: 25px;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.timu {
  font-weight: bold;
  font-size: 18px;
  margin-left: 10%;
  margin-bottom: 5%;
}

.xuanxiang {
  margin-left: 10%;
  margin-bottom: 10px;
  margin-right: 10%;
}
</style>